@import url("./scheme-colors.css");

/* Navbar */
.mobile-nav {
  color: #000 !important;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-nav-text {
  color: #000;
  font-size: 30px;
}

.canvas-bg {
  background-color: #f8f5f2;
}

.navlink-custom:hover {
  color: white !important;
}

/* Swiper in Brand section */
.c-item_title {
  z-index: 1;
  width: 100%;
  font-weight: bold;
  font-size: 32px;
  bottom: -5%;
  letter-spacing: 0.15em;
  position: absolute;
  text-align: center;
  color: var(--primary-color);
  font-family: var(--title-font);
}

.c-item_wm {
  width: 100%;
  font-size: 150px;
  letter-spacing: 0.15em;
  position: absolute;
  text-align: center;
  bottom: -25%;

  font-family: var(--title-font);
  color: transparent;
  -webkit-text-stroke: 1px var(--primary-color);
}

.swiper-pagination-bullet {
  background-color: var(--primary-color) !important;
}

/* Contact page */
.contact-section {
  padding: 130px 0 100px;
}

@media (max-width: 991px) {
  .contact-section {
    padding: 70px 0 40px;
  }
}

.contact-section .btn_2 {
  background-color: #191d34;
  padding: 18px 60px;
  border-radius: 50px;
  margin-top: 0;
}

.contact-section .btn_2:hover {
  background-color: #1f2b7b;
}

.contact-title {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}

.form-contact label {
  font-size: 14px;
}

.form-contact .form-group {
  margin-bottom: 30px;
}

.form-contact .form-control {
  border: 1px solid var(--primary-color);
  border-radius: 0px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

.form-contact .form-control:focus {
  outline: 0;
  box-shadow: none;
}

.form-contact .form-control::placeholder {
  font-weight: 300;
  color: var(--secondary);
}

.form-contact textarea {
  border-radius: 0px;
  height: 100% !important;
}

.contact-info {
  margin-bottom: 25px;
}

.contact-info__icon {
  margin-right: 10px;
}

.contact-info__icon i,
.contact-info__icon span {
  color: #8f9195;
  font-size: 27px;
}

.contact-info .media-body h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-size: 16px;
  color: #2a2a2a;
}

.contact-info .media-body h3 a:hover {
  color: #1f2b7b;
}

.contact-info .media-body p {
  color: #8a8a8a;
}

.contact-info__icon {
  margin-right: 20px;
}

.contact-info__icon i,
.contact-info__icon span {
  color: #8f9195;
  font-size: 27px;
}

.contact-info .media-body h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-size: 16px;
  color: #2a2a2a;
}

.contact-info .media-body h3 a:hover {
  color: #1f2b7b;
}

.contact-info .media-body p {
  color: #8a8a8a;
}

.custom-border {
  border: 2px solid var(--primary-color);
}

/* Date and time */
.slider__date {
  position: relative;
}

.slider__date input[type="radio"] {
  display: none;
}

.slider__date label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: transparent;
  border: 2px solid var(--primary-color);
}

.slider__date input[type="radio"]:checked + label {
  background-color: var(--primary-color);
  opacity: 1;
}

.calender__wrapper {
  top: 100%;
  left: 0;
  color: black;
  z-index: 10;
  background-color: #fafafa;
  border: 1px solid #fafafa;
}

.calendar__button {
  border: none;
  background: none;
  color: var(--primary-color);
  text-transform: uppercase;
}

.calendar__button span {
  margin-left: 8px;
  font-size: 20px;
}

.calendar__button:hover {
  border-bottom: 2px solid var(--primary-color);
}

.slider__date {
  position: relative;
}

.slider__date input[type="radio"] {
  display: none;
}

.slider__date label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: transparent;
  border: 2px solid var(--primary-color);
}

.slider__date input[type="radio"]:checked + label {
  background-color: var(--primary-color);
  opacity: 1;
}

.time__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.time__container .time__wrapp {
  position: relative;
  border: 1px solid var(--primary-color);
  text-align: center;
}

.time__container .time__wrapp label {
  width: 100%;
  height: 100%;
  padding: 10px;
  text-align: start;
  cursor: pointer;
  transition: 0.4s ease-in-out 0s;
  font-size: 17px;
  font-weight: 700;
  background: transparent;
}

.time__container .time__wrapp label:after,
.time__container .time__wrapp label:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.time__container .time__wrapp input[type="radio"] {
  display: none;
}

.time__container .time__wrapp label:after {
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary-color);
  right: 5px;
  top: calc(50% - 10px);
}

.time__container .time__wrapp label:before {
  background: #fff;
  height: 18px;
  width: 18px;
  right: 5px;
  top: 50%;
  top: calc(50% - 9px);
  transform: scale(5);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out 0s;
}

.time__container .time__wrapp input[type="radio"]:checked + label {
  background: var(--primary-color);
}

.time__container .time__wrapp input[type="radio"]:checked ~ label::before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.swiper-button-prev {
  border: 1px solid var(--primary-color) !important;
  width: 35px !important;
  padding: 5px !important;
  color: var(--primary-color) !important;
  height: 35px !important;
  border-radius: 100% !important;
}

.swiper-button-next {
  border: 1px solid var(--primary-color) !important;
  width: 35px !important;
  padding: 5px !important;
  color: var(--primary-color) !important;
  height: 35px !important;
  border-radius: 100% !important;
}

@media (max-width: 767px) {
  .mobile-view {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 260px;
  }
}


/* Otp component */
.custom-otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.custom-otp-input {
  width: 4rem !important;
  height: 4rem;
  color: var(--primary-color);
  font-size: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  text-align: center;
  margin: 0 5px;
}

.custom-separator {
  font-size: 20px;
  margin: 0 5px;
}

/* Navlink active */
.active-link {
  background-color: gray;
  border-radius: 100px;
  color: #fff;
}

@media (max-width: 678px) {
  .time__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .data-and-btn {
    margin-right: 40px;
  }

  .swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
  }

  .swiper-button-next {
    width: 30px !important;
    height: 30px !important;
  }

  .custom-otp-input {
    width: 40px !important;
    height: 40px !important;
    color: var(--primary-color);
    font-size: 20px;
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    text-align: center;
    margin: 0 5px;
  }
}

/* Select Employee component */
.category-border {
  border: 2px solid var(--primary-color);
}

.service-bg {
  background-color: var(--primary-color);
  transition: 0.2s all ease;
}

/* Select Service component  */
.service-bg {
  background-color: var(--primary-color);
  transition: 0.2s all ease;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .contact-section {
    padding: 80px 0 50px;
  }
}
